'use client';

import { Box, Drawer, Stack, type Theme } from '@mui/material';
import { type DrawerProps, drawerClasses } from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { type FC, type ReactNode, useMemo } from 'react';
import { useAppShell } from './context';

type Props = {
  containerWidth?: string | number;
  drawerWidth?: string | number;
  drawerMobileWidth?: string | number;
  children: ReactNode;
  DrawerRender: ReactNode;
  mdVariant?: DrawerProps['variant'];
};

export const AppShell: FC<Props> = (props) => {
  const {
    children,
    containerWidth = 'auto',
    drawerWidth = '30%',
    drawerMobileWidth = '80%',
    DrawerRender,
    mdVariant = 'permanent',
  } = props;

  const { isVisible, onClose } = useAppShell();

  const mdWidth = useMemo(() => {
    if (mdVariant !== 'permanent') {
      return isVisible ? drawerWidth : 0;
    }

    return drawerWidth;
  }, [mdVariant, isVisible, drawerWidth]);

  const mdFlexShrink = useMemo(() => {
    if (mdVariant !== 'permanent') {
      return isVisible ? 0 : 1;
    }

    return 0;
  }, [mdVariant, isVisible]);

  const isUpToMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const hideOnMobile = useMemo(() => {
    return drawerMobileWidth === 0 && !isUpToMd;
  }, [drawerMobileWidth, isUpToMd]);

  if (hideOnMobile) {
    return (
      <Box
        component="div"
        width="100%"
        minHeight="100%"
        overflow={{ none: 'auto', md: 'hidden auto' }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Stack direction="row" minHeight="100%" width={containerWidth}>
      <Box
        component="nav"
        sx={{
          width: { md: mdWidth },
          flexShrink: { md: mdFlexShrink },
          transition: 'all 300ms',
        }}
      >
        {!isUpToMd && (
          <Drawer
            variant="temporary"
            anchor="left"
            open={isVisible}
            onClose={onClose}
            sx={{
              display: { xs: 'block', md: 'none' },
              [`& .${drawerClasses.paper}`]: {
                boxSizing: 'border-box',
                width: drawerMobileWidth,
                border: 'none',
              },
            }}
            PaperProps={{
              variant: 'elevation',
              elevation: 3,
            }}
          >
            {DrawerRender}
          </Drawer>
        )}
        {isUpToMd && (
          <Drawer
            variant={mdVariant}
            sx={{
              display: { none: 'none', md: 'block' },
              [`& .${drawerClasses.paper}`]: {
                boxSizing: 'border-box',
                width: drawerWidth,
                border: 'none',
                zIndex: 999,
              },
            }}
            open={isVisible}
            onClose={onClose}
            PaperProps={{
              variant: 'elevation',
              elevation: 3,
            }}
          >
            {DrawerRender}
          </Drawer>
        )}
      </Box>
      <Box
        component="div"
        width="100%"
        minHeight="100%"
        overflow={{ none: 'auto', md: 'hidden auto' }}
      >
        {children}
      </Box>
    </Stack>
  );
};
