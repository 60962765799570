'use client';

import {
  type FC,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

type AppShellContextT = {
  isVisible: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
};

const AppShellContext = createContext<AppShellContextT | undefined>(undefined);

export const useAppShell = () => {
  const ctx = useContext(AppShellContext);

  if (!ctx) {
    throw new Error('useAppShell() must be call inside <AppShellProvider/>');
  }

  return ctx;
};

export type AppShellProviderProps = {
  isVisible?: boolean;
  children: ReactNode;
};

export const AppShellProvider: FC<AppShellProviderProps> = (props) => {
  const { isVisible: defaultVisibility = true, children } = props;

  const [isVisible, setIsVisible] = useState<boolean>(defaultVisibility);

  const onOpen = useCallback(() => setIsVisible(true), []);

  const onClose = useCallback(() => setIsVisible(false), []);

  const onToggle = useCallback(() => setIsVisible((prev) => !prev), []);

  return (
    <AppShellContext.Provider value={{ isVisible, onOpen, onClose, onToggle }}>
      {children}
    </AppShellContext.Provider>
  );
};
