'use client';

import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { ComponentProps, FC } from 'react';
import styles from './po-loader.module.css';

type Props = {
  scale?: number;
  texts?: string[];
  sx?: ComponentProps<typeof Box>['sx'];
};

export const PoLoader: FC<Props> = (props) => {
  const { scale = 1, texts = [], sx = {} } = props;

  const theme = useTheme();

  return (
    <Stack className={styles['triangle-loader-container']}>
      <Box
        component="div"
        className={styles.loader}
        sx={{ transform: `scale(${scale})`, ...sx }}
      >
        <svg viewBox="0 0 86 80" color={theme.palette.primary.main}>
          <polygon points="43 8 79 72 7 72" />
        </svg>
      </Box>
      {texts.length !== 0 && (
        <Box component="div" className={styles['loading-phrases-container']}>
          <Box component="div" className={styles['loading-phrases']}>
            {texts.map((text, i) => (
              <Typography key={text}>{text}</Typography>
            ))}
          </Box>
        </Box>
      )}
    </Stack>
  );
};
