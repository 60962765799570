import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    NEXT_RUNTIME: z.string().optional(),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    STRIPE_SECRET_KEY: z.string(),
    POLE_EMPLOI_OAUTH_BASE_URL: z.string(),
    POLE_EMPLOI_API_BASE_URL: z.string(),
    POLE_EMPLOI_CLIENT_ID: z.string(),
    POLE_EMPLOI_CLIENT_SECRET: z.string(),
    POLE_EMPLOI_ISS: z.string(),
    POLE_EMPLOI_SCOPES: z.string(),
    SUPABASE_SERVICE_ROLE_KEY: z.string(),
    SUPABASE_URL: z.string().url(),
    JWT_INVITATION: z.string(),
    SENTRY_ORG: z.string().nullish(),
    SENTRY_PROJECT: z.string().nullish(),
    SENTRY_AUTH_TOKEN: z.string().nullish(),
    VERCEL_GIT_COMMIT_SHA: z.string().nullish(),
    GROWTHBOOK_WEBHOOK_SECRET: z.string().optional(),
    WORKER_AURA_ENGINE_API_KEY: z.string(),
    WORKER_AURA_ENGINE_URL: z.string().url(),
    WORKER_HELIO_ENGINE_API_KEY: z.string(),
    WORKER_HELIO_ENGINE_URL: z.string().url(),
    UMAMI_TRACKING_URL: z.string().url().optional(),
  },
  client: {
    NEXT_PUBLIC_SUPABASE_URL: z.string().url(),
    NEXT_PUBLIC_BASE_API_URL: z.string().url(),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string(),
    NEXT_PUBLIC_TOOLS_URL: z.string().url(),
    NEXT_PUBLIC_ONBOARDING_URL: z.string().url(),
    NEXT_PUBLIC_AURA_URL: z.string().url(),
    NEXT_PUBLIC_HELIO_URL: z.string().url(),
    NEXT_PUBLIC_QUARK_URL: z.string().url(),
    NEXT_PUBLIC_APP_URL: z.string().url(),
    NEXT_PUBLIC_WORKER_QUARK_ENGINE_URL: z.string(),
    NEXT_PUBLIC_WORKER_QUARK_ENGINE_API_KEY: z.string({ coerce: true }),
    NEXT_PUBLIC_WORKER_POTENTIAL_ENGINE_URL: z.string(),
    NEXT_PUBLIC_WORKER_POTENTIAL_ENGINE_API_KEY: z.string({ coerce: true }),
    NEXT_PUBLIC_USERMAVEN_KEY: z.string().default(''),
    NEXT_PUBLIC_APP_ENV: z.enum([
      'development',
      'test',
      'staging',
      'production',
    ]),
    NEXT_PUBLIC_COOKIE_DOMAIN: z.string(),
    NEXT_PUBLIC_COOKIE_NAME: z.string(),
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string(),
    NEXT_PUBLIC_HUBSPOT_SCRIPT: z.string().url(),
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string(),
    NEXT_PUBLIC_WORKER_PDF_URL: z.string(),
    NEXT_PUBLIC_FLAGS_URL: z.string().url(),
    NEXT_PUBLIC_FLAGS_FILE_NAME: z.string(),
    NEXT_PUBLIC_SENTRY_DSN: z.string().nullish(),
    NEXT_PUBLIC_GROWTHBOOK_API_HOST: z.string().optional(),
    NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY: z.string().optional(),
    NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY: z.string().optional(),
    NEXT_PUBLIC_UMAMI_WEBSITE_ID: z.string().optional(),
  },
  runtimeEnv: {
    NEXT_RUNTIME: process.env.NEXT_RUNTIME,
    NODE_ENV: process.env.NODE_ENV,
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    POLE_EMPLOI_OAUTH_BASE_URL: process.env.POLE_EMPLOI_OAUTH_BASE_URL,
    POLE_EMPLOI_API_BASE_URL: process.env.POLE_EMPLOI_API_BASE_URL,
    POLE_EMPLOI_CLIENT_ID: process.env.POLE_EMPLOI_CLIENT_ID,
    POLE_EMPLOI_CLIENT_SECRET: process.env.POLE_EMPLOI_CLIENT_SECRET,
    POLE_EMPLOI_ISS: process.env.POLE_EMPLOI_ISS,
    POLE_EMPLOI_SCOPES: process.env.POLE_EMPLOI_SCOPES,
    SUPABASE_SERVICE_ROLE_KEY: process.env.SUPABASE_SERVICE_ROLE_KEY,
    SUPABASE_URL: process.env.SUPABASE_URL,
    JWT_INVITATION: process.env.JWT_INVITATION,
    SENTRY_ORG: process.env.SENTRY_ORG,
    SENTRY_PROJECT: process.env.SENTRY_PROJECT,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    VERCEL_GIT_COMMIT_SHA: process.env.VERCEL_GIT_COMMIT_SHA,
    WORKER_AURA_ENGINE_API_KEY: process.env.WORKER_AURA_ENGINE_API_KEY,
    WORKER_AURA_ENGINE_URL: process.env.WORKER_AURA_ENGINE_URL,
    WORKER_HELIO_ENGINE_API_KEY: process.env.WORKER_HELIO_ENGINE_API_KEY,
    WORKER_HELIO_ENGINE_URL: process.env.WORKER_HELIO_ENGINE_URL,
    UMAMI_TRACKING_URL: process.env.UMAMI_TRACKING_URL,
    NEXT_PUBLIC_UMAMI_WEBSITE_ID: process.env.NEXT_PUBLIC_UMAMI_WEBSITE_ID,

    NEXT_PUBLIC_BASE_API_URL: process.env.NEXT_PUBLIC_BASE_API_URL,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    NEXT_PUBLIC_TOOLS_URL: process.env.NEXT_PUBLIC_TOOLS_URL,
    NEXT_PUBLIC_ONBOARDING_URL: process.env.NEXT_PUBLIC_ONBOARDING_URL,
    NEXT_PUBLIC_AURA_URL: process.env.NEXT_PUBLIC_AURA_URL,
    NEXT_PUBLIC_HELIO_URL: process.env.NEXT_PUBLIC_HELIO_URL,
    NEXT_PUBLIC_QUARK_URL: process.env.NEXT_PUBLIC_QUARK_URL,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_WORKER_QUARK_ENGINE_URL:
      process.env.NEXT_PUBLIC_WORKER_QUARK_ENGINE_URL,
    NEXT_PUBLIC_WORKER_QUARK_ENGINE_API_KEY:
      process.env.NEXT_PUBLIC_WORKER_QUARK_ENGINE_API_KEY,
    NEXT_PUBLIC_WORKER_POTENTIAL_ENGINE_URL:
      process.env.NEXT_PUBLIC_WORKER_POTENTIAL_ENGINE_URL,
    NEXT_PUBLIC_WORKER_POTENTIAL_ENGINE_API_KEY:
      process.env.NEXT_PUBLIC_WORKER_POTENTIAL_ENGINE_API_KEY,
    NEXT_PUBLIC_USERMAVEN_KEY: process.env.NEXT_PUBLIC_USERMAVEN_KEY,
    NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
    NEXT_PUBLIC_COOKIE_DOMAIN: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    NEXT_PUBLIC_COOKIE_NAME: process.env.NEXT_PUBLIC_COOKIE_NAME,
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY:
      process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    NEXT_PUBLIC_HUBSPOT_SCRIPT: process.env.NEXT_PUBLIC_HUBSPOT_SCRIPT,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_WORKER_PDF_URL: process.env.NEXT_PUBLIC_WORKER_PDF_URL,
    NEXT_PUBLIC_FLAGS_URL: process.env.NEXT_PUBLIC_FLAGS_URL,
    NEXT_PUBLIC_FLAGS_FILE_NAME: process.env.NEXT_PUBLIC_FLAGS_FILE_NAME,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    GROWTHBOOK_WEBHOOK_SECRET: process.env.GROWTHBOOK_WEBHOOK_SECRET,
    NEXT_PUBLIC_GROWTHBOOK_API_HOST:
      process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
    NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY:
      process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY:
      process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY,
  },
});
